import { Feature } from "ol";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import MarkerImage from '../../../assets/img/marker.png';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

export const createMarker = (image, longitude = 0, latitude = 0) => {
  const marker = new Feature({
    geometry: new Point(fromLonLat([longitude, latitude])),
  });
  marker.setStyle(new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: image,
    }),
  }));

  return marker;
}

const createPlaceMarker = (place) => {
  const marker = createMarker(MarkerImage, place.longitude, place.latitude);
  marker.set('place', place);

  return marker;
}

export const createPlaceMarkers = (places) => {
  const markers = places.map(place => createPlaceMarker(place));
  return new VectorLayer({
    source: new VectorSource({
      features: markers
    }),
    name: 'markers'
  });
}