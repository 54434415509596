import './PlacesMap.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat, toLonLat } from 'ol/proj';
import { useEffect, useState } from 'react';
import { createMarker, createPlaceMarkers } from './Marker';
import MarkerImage2 from '../../../assets/img/marker2.png';
import { Point } from 'ol/geom';

function initializeMap(map, setMap) {
  if(!map || document.getElementById("map-content").innerHTML === "") {
    setMap(new Map({
      target: 'map-content',
      layers: [
        new TileLayer({
          source: new OSM(),
        })
      ],
      view: new View({
        center: fromLonLat([18.6466, 54.3485]),
        zoom: 12,
        maxZoom: 20
      })
    }));
  }
}

function initializeMapInteractions(setSelectedPlace, map) {
  map.on("click", event => {
    const marker = map.getFeaturesAtPixel(event.pixel)[0];
    if(marker && marker.getProperties().place) {
      const markerPlace = marker.getProperties().place;
      setSelectedPlace(markerPlace);
      return;
    } 

    const lonlat = map.getCoordinateFromPixel(event.pixel);
    const [roundedLon, roundedLat] = toLonLat(lonlat).map(coord => parseFloat(coord.toFixed(6)));
    setSelectedPlace({ longitude: roundedLon, latitude: roundedLat });
  });

  map.on("pointermove", event => {
    if(!event.dragging) {
      map.getTargetElement().style.cursor = map.hasFeatureAtPixel(map.getEventPixel(event.originalEvent)) ? 'pointer' : '';
    }
  })
}

function getMarkersLayer(map) {
  return map.getLayers().getArray().find(layer => layer.get('name') === 'markers');
}

const PlacesMap = ({ places, selectedPlace, setSelectedPlace, map, setMap }) => {
  const [clickedMarker] = useState(createMarker(MarkerImage2));

  useEffect(() => {
    if(!map) {
      return;
    }

    const markersLayerSource = getMarkersLayer(map)?.getSource();
    if(!markersLayerSource) {
      return;
    }

    if(!selectedPlace || !selectedPlace.latitude || !selectedPlace.longitude || selectedPlace.id) {
      markersLayerSource.removeFeature(clickedMarker);
      return;
    }

    if(!markersLayerSource.hasFeature(clickedMarker)) {
      markersLayerSource.addFeature(clickedMarker);
    }
    
    clickedMarker.setGeometry(new Point(fromLonLat([selectedPlace.longitude, selectedPlace.latitude])));
  }, [selectedPlace, map, clickedMarker]);

  useEffect(() => {
    if(!places || !map) {
      return;
    }

    map.removeLayer(getMarkersLayer(map));
    map.addLayer(createPlaceMarkers(places))
  }, [places, map, clickedMarker]);

  useEffect(() => {
    if(!setMap) {
      return;
    }
    
    initializeMap(map, setMap);
    if(setSelectedPlace && map) {
      initializeMapInteractions(setSelectedPlace, map);
    }
  }, [setSelectedPlace, map, setMap]);

  return (
    <div id="map-content"></div>
  );
}

export default PlacesMap;
