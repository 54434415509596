import 'ol/ol.css';
import PlacesMap from "./placesMap/PlacesMap"
import Popup from "./popup/Popup"
import { useState } from 'react';

const MapWithPopup = ({ places, selectedPlace, setSelectedPlace, setActivePanel }) => {
    const [map, setMap] = useState(null);
    return (
        <>
        <PlacesMap places={places} selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace} map={map} setMap={setMap} />
        <Popup place={selectedPlace} setSelectedPlace={setSelectedPlace} setActivePanel={setActivePanel} map={map}/>
        </>
    )
}

export default MapWithPopup;