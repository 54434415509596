import { useEffect, useState } from "react";
import { getRanking } from "../../../../services/PlaceService";

export default function RankingPanel() {
    const [ranking, setRanking] = useState(null);

    useEffect(() => {
        getRanking()
            .then(data => setRanking(data));
    }, []);

    if(!ranking) {
        return <></>;
    }

    return (
      <div className="panel-centered">
        <h1>Ranking miejsc:</h1>
        <ol className='place-ranking'>
          {ranking.map(place => {
            return (
              <li key={place.id}>{place.name} ★{place.averageScore}</li>
            );
          })}
        </ol>
      </div>
    )
}   