import apiConfig from "../config/apiConfig";
import { callJsonApi } from "../util/ApiCaller";

export async function getPlaceEvents(placeId) {
    const { status, data } = await callJsonApi(apiConfig.place + `/${placeId}/events`, "GET");    
    if(status !== 200) {       
        return null;
    }
    return data;    
}

export async function getUpcomingEvent(placeId) {
    const { status, data } = await callJsonApi(apiConfig.place + `/${placeId}/events/upcoming`, "GET");
    if(status !== 200) {
        return null;
    }
    return data;
}

