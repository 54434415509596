import { useEffect, useRef, useState } from "react";
import apiConfig from "../../../../config/apiConfig";
import { getPlacePhotosIds } from "../../../../services/PlaceService";
import { deletePhoto, uploadPhoto } from "../../../../services/PhotoService";
import { useAuth } from "../../../../auth/AuthProvider";

export default function PhotosPanel({ selectedPlace }) {
    const { role } = useAuth();
    const hiddenFileInput = useRef(null);
    const [photosIds, setPhotosIds] = useState(null);

    useEffect(() => {
        if(selectedPlace?.id) {
            getPlacePhotosIds(selectedPlace.id)
                .then(data => setPhotosIds(data));
        }
    }, [selectedPlace]);

    const handlePhotosInputChange = async event => {
        const file = event.target.files[0];
        await uploadPhoto(selectedPlace.id, file);

        getPlacePhotosIds(selectedPlace.id)
            .then(data => setPhotosIds(data));
    }

    const handleDeletePhoto = async photoId => {
        if(await deletePhoto(photoId))
        {
            getPlacePhotosIds(selectedPlace.id)
               .then(data => setPhotosIds(data));
        }
    }

    return (
        <div className="photos-panel">
            <div className="photos-panel-header">
                <div className="photos-header-space"/>
                <div className="photos-header-text">
                    <h1>Zdjęcia miejsca</h1>
                    <h2 className='place-name'>{selectedPlace.name}</h2>
                </div>
                <div className="photos-header-add-button">
                    <button onClick={() => hiddenFileInput.current.click()}>Dodaj</button>
                    <input ref={hiddenFileInput} type="file" accept=".jpg,.jpeg,image/png" style={{display: "none"}} onChange={handlePhotosInputChange}/>
                </div>
            </div>
            <div className='place-photos'>
                {photosIds && photosIds.map(photoId => {
                    return (
                        <div className="place-photo">
                            <img src={apiConfig.photo + '/' + photoId} alt={'Zdjęcie ' + photoId} />
                            { role === 'Admin' && <i className="delete-photo fa-solid fa-trash" onClick={() => handleDeletePhoto(photoId)} />}
                        </div>
                    );
                })}
            </div>
        </div>
    )
}