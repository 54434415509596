import { nearestPlace } from "../../../../services/PlaceService";

export default function FindNearestPanel({ selectedPlace, setSelectedPlace }) {
    const findNearestPlace = async () => {
        if (!selectedPlace) {
            return;
        }
        const nearestPlaceBody = {
            longitude: selectedPlace.longitude,
            latitude: selectedPlace.latitude
        }
        const result = await nearestPlace(nearestPlaceBody);
        if (result) {
            setSelectedPlace(result);
        }
    }

    return (
        <div className="panel-centered">
            <h1>Znajdź najbliższe miejsce</h1>
            <input
                type="text"
                name="longitude"
                placeholder="Długość geograficzna..."
                value={selectedPlace?.longitude?.toFixed(6) ?? ''}
                readOnly
            />
            <input
                type="text"
                name="latitude"
                placeholder="Szerokość geograficzna..."
                value={selectedPlace?.latitude?.toFixed(6) ?? ''}
                readOnly
            />
            <button onClick={findNearestPlace}>Znajdź miejsce</button>
        </div>
    )
}