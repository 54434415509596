import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
  
const ProtectedRoute = ({ unauthenticatedFallback, children }) => {
  const { loading, username } = useAuth();

  if(loading) {
    return (
      <div className='loading'>
          Ładowanie
      </div>
      );
  }
  if (!username) {
    return unauthenticatedFallback ?? <Navigate to="/login" replace />;
  }
  return children;
};

export default ProtectedRoute;