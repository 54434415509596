import { useAuth } from '../../../auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import './Popup.css'
import { userControlPanels } from '../../pages/user/User';
import { getPlaceRatings } from '../../../services/PlaceService';
import { useEffect, useState } from 'react';
import { getUpcomingEvent } from '../../../services/EventService';
import { fromLonLat } from 'ol/proj';
import PopupRatings from './sections/PopupRatings';
import PopupUpcomingEvent from './sections/PopupUpcomingEvent';
import { Overlay } from 'ol';

const addPopupToMap = (map, setPopup) => {
  const newPopup = new Overlay({
    element: document.querySelector("#popup"),
    autoPan: true
  });
  map.addOverlay(newPopup);

  setPopup(newPopup);
}

const Popup = ({place, setSelectedPlace, setActivePanel, map}) => {
  const [popup, setPopup] = useState(null);
  const navigate = useNavigate();
  const {loading, role, userId} = useAuth();

  const [ratings, setRatings] = useState(null);
  const [upcomingEvent, setUpcomingEvent] = useState(null);

  useEffect(() => {
    map && popup == null && addPopupToMap(map, setPopup);
  }, [map, popup]);

  useEffect(() => {
    popup?.setPosition(undefined);

    if(place?.id) {
      async function getRatingsAndEvent() {
        setRatings(await getPlaceRatings(place.id));
        setUpcomingEvent(await getUpcomingEvent(place.id));
        popup?.setPosition(fromLonLat([place.longitude, place.latitude]));
      }

      getRatingsAndEvent();
    }
  }, [place, popup]);

  return (
    <div id='popup' className="popup-content">
      <div className="popup-header">
        <h2 className="popup-title">{place?.name}</h2>
        <h2 className="popup-avg-rating">★ {place?.averageScore}</h2>
        <button className="popup-close" onClick={() => setSelectedPlace({})}>✖</button>
      </div>
      <div className="popup-info">
        <p className="coordinates">Współrzędne: {place?.latitude?.toFixed(6)}, {place?.longitude?.toFixed(6)}</p>
        <p className="address">Adres: {place?.address}</p>
        <p className="description">Opis: {place?.description}</p>
      </div>

      <PopupRatings ratings={ratings} />
      <PopupUpcomingEvent upcomingEvent={upcomingEvent} />

      <div className="popup-buttons">
        <button onClick={() => navigate(`/event?placeid=${place.id}`)}>Zobacz wydarzenia</button>
        {
          !loading && userId === place?.createdById &&          
          <button onClick={() => navigate(`/event/create?placeid=${place.id}`)}>Utwórz wydarzenie</button>
        }        
        <button onClick={() => setActivePanel(userControlPanels.rate)}>Oceń</button>        
        <button onClick={() => setActivePanel(userControlPanels.photos)}>Zobacz zdjęcia</button>
        {
          !loading && role === 'Admin' &&
          <button onClick={() => setActivePanel(userControlPanels.delete)}>Usuń</button>
        }
      </div>
    </div>
  );
}

export default Popup;