import './PopupRatings.css';

const generateStars = (score) => {
  const stars = [];
  for (let i = 0; i < score; i++) {
    stars.push(<span key={i}>★</span>);
  }
  return stars;
};

const PopupRatings = ({ ratings }) => {
  if(!ratings) {
    return <></>;
  }
  
  return (
    <>
    <h3>Opinie</h3>
    <div className='popup-ratings'>
      {ratings.map((rating, i) => {
        return (
          <div className='popup-rating' key={i}>
            <div className='popup-rating-header'>
              <h4 className='popup-rating-header-username'>{rating.createdBy.username}</h4>
              <h4>{generateStars(rating.score)}</h4>
            </div>
            {rating.text &&
            <div className='popup-rating-comment'>
              {rating.text}
            </div>}
          </div>
        )
      })}
    </div>
    </>
  );
}

export default PopupRatings;