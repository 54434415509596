import { useEffect, useState } from "react";
import { addPlace, editPlace, getPlaceTypes } from "../../../../services/PlaceService";

export default function AddEditPanel ({ selectedPlace, refresh }) {
    const [placeInputs, setPlaceInputs] = useState({...selectedPlace});
    const [placeTypes, setPlaceTypes] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    useEffect(() =>{
        getPlaceTypes()
            .then(data => setPlaceTypes(data));
    }, []);

    useEffect(() => {
        if(!selectedPlace?.placeTypeId) {
            selectedPlace.placeTypeId = placeTypes[0]?.id ?? 0;
        }
        setPlaceInputs({...selectedPlace});
        setEditMode(!!selectedPlace?.id);
    }, [selectedPlace, placeTypes]);

    const executeAddPlace = async() => {
        if(!placeInputs) {
            return;
        }
        if(!placeInputs.placeTypeId) {
            return;
        }
        placeInputs.coordinates = {
            longitude: placeInputs.longitude,
            latitude: placeInputs.latitude
        }
    
        const result = await addPlace(placeInputs);
        if(result) {
            refresh();
            setShowSuccessPopup(true);
        }
    }
    const executeEditPlace = async() => {
        if(!placeInputs) {
            return;
        }
    
        const result = await editPlace(selectedPlace.id, placeInputs);
        if(result) {
            refresh();
        }
    }

    return (
        <div className="panel-centered">
            {showSuccessPopup && (
                <div className="success-popup">
                    <p>Miejsce zostało przekazane do weryfikacji</p>
                    <button onClick={() => setShowSuccessPopup(false)}>Zamknij</button>
                </div>
            )}

            <h1>{editMode ? "Edytuj miejsce na mapie" : "Dodaj nowe miejsce do mapy"}</h1>

            <input type="text" placeholder="Nazwa..." value={placeInputs.name ?? ''} onChange={e => setPlaceInputs({...placeInputs, name: e.target.value})} />
            <input type="text" placeholder="Adres..." value={placeInputs.address ?? ''} onChange={e => setPlaceInputs({...placeInputs, address: e.target.value})} />
            <input type="text" placeholder="Opis..." value={placeInputs.description ?? ''} onChange={e => setPlaceInputs({...placeInputs, description: e.target.value})} />
            <input type="text" placeholder="Dostępność..." value={placeInputs.availability ?? ''} onChange={e => setPlaceInputs({...placeInputs, availability: e.target.value})} />
            <select value={placeInputs.placeTypeId ?? placeTypes[0]?.id ?? 0} onChange={e => setPlaceInputs({...placeInputs, placeTypeId: e.target.value})}>
                {placeTypes?.map(placeType => (
                    <option key={placeType.id} value={placeType.id}>{placeType.name}</option>
                ))}
            </select>

            <button onClick={editMode ? executeEditPlace : executeAddPlace}>{editMode ? "Edytuj miejsce" : "Dodaj miejsce"}</button>
        </div>
    );
}